import Lightbox from "stimulus-lightbox"
import lgVideo from "lightgallery/plugins/video";
import Thumbnail from "lightgallery/plugins/thumbnail";
import Hash from "lightgallery/plugins/hash";
import Share from "lightgallery/plugins/share";

export default class extends Lightbox {
    connect() {
        super.connect()
        // console.log(this.defaultOptions)

        // console.log(this.data.get('optionsValue'));
        // console.log(this.data.get('options'));

        // Default options for every lightboxes.
        this.defaultOptions

    }



    // You can set default options in this getter.
    get defaultOptions () {
        return {


            speed: 400,
            plugins: [lgVideo,Thumbnail,Hash,Share],
            selector: '.item-lightbox'
            // Your default options here
        }
    }
}